import withTracer from '../Utilities/withTracer'
import AppLayout , {reportWebVitals} from '@speedwaymotors/clutch/Layout/Next/_app'

const TracedAppWithCustomLayout = withTracer(AppLayout, 'PrpApp');

export {reportWebVitals}
export default TracedAppWithCustomLayout



